
export const BASE_URL = process.env.NEXT_PUBLIC_BASE_URL || ''
export const NEXT_PUBLIC_HEAPANALYTICS_KEY = process.env.NEXT_PUBLIC_HEAPANALYTICS_KEY || ''
export const NEXT_PUBLIC_FACEBOOK_PIXEL_ID = process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID
export const NEXT_PUBLIC_GOOGLE_AUTH_ENABLE = process.env.NEXT_PUBLIC_GOOGLE_AUTH_ENABLE || ''
export const NEXT_PUBLIC_GOOGLE_CLIENT_ID = process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID || ''
export const NEXT_PUBLIC_APP_VERSION = process.env.NEXT_PUBLIC_APP_VERSION || ''
export const NEXT_PUBLIC_PIANCO_URL = process.env.NEXT_PUBLIC_PIANCO_URL || 'https://pianco.meetlala.io/landingv2'
export const NEXT_PUBLIC_LOGO = process.env.NEXT_PUBLIC_LOGO || '/logo.png'

