export function getSiteName(): string {
  if (process.env.NEXT_PUBLIC_SITE_NAME) {
    return <string>process.env.NEXT_PUBLIC_SITE_NAME
  }
  return 'LaLa'
}

export function getSiteDomain(): string {
  if ('NEXT_PUBLIC_DOMAIN' in process.env) {
    const domain = <string>process.env.NEXT_PUBLIC_DOMAIN
    return domain.replace(/\/+$/, '')
  }

  console.warn(
    "ENV 'PUBLIC_DOMAIN' has no value, using default:",
    'https://app.meetlala.io/',
  )
  return 'https://app.meetlala.io/'
}

export function getRedisURL(): string {
  if ('REDIS_URL' in process.env) {
    return <string>process.env.REDIS_URL
  }

  console.warn(
    "ENV 'REDIS_URL' has no value, using default:",
    'redis://localhost:6379',
  )
  return 'redis://localhost:6379'
}

export function getDefaultSrc(): string {
  if (process.env.NEXT_PUBLIC_DEFAULT_SRC) {
    return <string>process.env.NEXT_PUBLIC_DEFAULT_SRC
  }
  console.warn("ENV 'DEFAULT_SRC' has no value, using no src")
  return 'https://youtu.be/Dy7voQjQZiA'
}

export function getTutorialSrc(): string {
  if (process.env.NEXT_PUBLIC_TUTORIAL_SRC) {
    return <string>process.env.NEXT_PUBLIC_TUTORIAL_SRC
  }
  console.warn("ENV 'DEFAULT_SRC' has no value, using no src")
  return 'https://meetlala-onboarding.s3.us-east-2.amazonaws.com/_TUTORIAL+VIDEO.mp4'
}
