import React, {
  createContext,
  FC,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from "react"
import { useInterval, useLocalStorage } from "usehooks-ts"

interface Group {
  name: string
  logo: string
  created_at: string
}

export interface RawRoom {
  created_at: string
  creator: string
  id: string
  name: string
  lifetime: number
  status: boolean
  url_guest: string
  url_host: string
  whereby_meeting_id: string
  whereby_meeting_name: string
  group: Group
  collect_emails: boolean
}

export class Room {
  createdAt: string
  creator: string
  id: string
  name: string
  lifetime: number
  status: boolean
  urlGuest: string
  urlHost: string
  wherebyMeetingId: string
  wherebyMeetingName: string
  group: Group
  collect_emails: boolean

  constructor(rawRoom: RawRoom) {
    this.createdAt = rawRoom.created_at
    this.creator = rawRoom.creator
    this.id = rawRoom.id
    this.name = rawRoom.name
    this.lifetime = rawRoom.lifetime
    this.status = rawRoom.status
    this.urlGuest = rawRoom.url_guest
    this.urlHost = rawRoom.url_host
    this.wherebyMeetingId = rawRoom.whereby_meeting_id
    this.wherebyMeetingName = rawRoom.whereby_meeting_name
    this.group = rawRoom.group
    this.collect_emails = rawRoom.collect_emails
  }
}

interface Participant {
  id: string;
  displayName: string;
}

interface RoomContextProps {
  currentRoom: Room | null
  activeRoom: Room | null // active instant room
  setActiveRoom: (key: Room) => void
  setCurrentRoom: (key: Room) => void
  remoteParticipants: Participant[];
  setRemoteParticipants: (participants: Participant[]) => void;
}

export const RoomContext = createContext<RoomContextProps | null>(null)

export interface RoomContextProviderProps {
  children: ReactElement | ReactElement[]
}

const CheckerActiveRoom: FC<{
  createdAt: Date
  lifetime: number
  setActiveRoom: (item: Room | null) => void
}> = ({ createdAt, lifetime, setActiveRoom }) => {
  const lifetimeMS = lifetime * 60 * 1000
  const createdAtTimestamp = createdAt.getTime()
  const expiryTime = lifetimeMS + createdAtTimestamp
  useInterval(() => {
    if (expiryTime < new Date().getTime()) {
      setActiveRoom(null)
    }
  }, 1000)
  return null
}

const RoomContextProvider: FC<RoomContextProviderProps> = ({ children }) => {
  const [activeRoom, setActiveRoom] = useState<Room | null>(null)
  const [currentRoom, setCurrentRoom] = useState<Room | null>(null)
  const [remoteParticipants, setRemoteParticipants] = useState<Participant[]>([]);

  const [activeRoomStorage, setActiveRoomStorage] =
    useLocalStorage<null | Room>("activeRoom", null)

  useEffect(() => {
    if (activeRoomStorage) {
      setActiveRoom(activeRoomStorage)
    }
  }, [])

  useEffect(() => {
    setActiveRoomStorage(activeRoom)
  }, [activeRoom, setActiveRoomStorage])
  return (
    <>
      {activeRoom && (
        <CheckerActiveRoom
          createdAt={new Date(activeRoom.createdAt)}
          lifetime={activeRoom.lifetime}
          setActiveRoom={setActiveRoom}
        />
      )}
      <RoomContext.Provider
        value={{
          currentRoom,
          activeRoom,
          setActiveRoom: (room: Room) => {
            setActiveRoom(room)
          },
          setCurrentRoom: (room: Room) => {
            setCurrentRoom(room)
          },
          remoteParticipants,
          setRemoteParticipants,
        }}
      >
        {children}
      </RoomContext.Provider>
    </>
  )
}

const useRoomContext = () => {
  const context = useContext(RoomContext)
  const handleSetActiveRoom = () => null
  return {
    state: {
      activeRoom: context?.activeRoom,
      currentRoom: context?.currentRoom,
      remoteParticipants: context?.remoteParticipants || [],
    },
    actions: {
      setActiveRoom: context?.setActiveRoom || handleSetActiveRoom,
      setCurrentRoom: context?.setCurrentRoom || handleSetActiveRoom,
      setRemoteParticipants: context?.setRemoteParticipants || (() => {}),
    },
  }
}

export { RoomContextProvider, useRoomContext }