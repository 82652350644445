import React, { createContext, useContext, useEffect, useState } from 'react'

const UserInteractionContext = createContext()

export const UserInteractionProvider = ({ children }) => {
  const [hasInteracted, setHasInteracted] = useState(false)

  const handleInteraction = () => {
    setHasInteracted(true)
  }

  useEffect(() => {
    const handleClick = () => {
      handleInteraction()
    }

    document.addEventListener('click', handleClick)

    return () => {
      document.removeEventListener('click', handleClick)
    }
  }, [handleInteraction])

  return (
    <UserInteractionContext.Provider value={{ hasInteracted, handleInteraction }}>
      {children}
    </UserInteractionContext.Provider>
  )
}

export const useUserInteraction = () => {
  const context = useContext(UserInteractionContext)
  if (!context) {
    throw new Error('useUserInteraction must be used within a UserInteractionProvider')
  }
  return context
}
