import { useMemo } from 'react'

export const useEnv = () => useMemo(() => ({
  NEXT_PUBLIC_FACEBOOK_PIXEL_ID: process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID,
  NEXT_PUBLIC_PIANCO_URL: process.env.NEXT_PUBLIC_PIANCO_URL || 'https://pianco.meetlala.io/landingv2',
  NEXT_PUBLIC_HEAPANALYTICS_KEY: process.env.NEXT_PUBLIC_HEAPANALYTICS_KEY,
  NEXT_PUBLIC_SENTRY_DSN: process.env.NEXT_PUBLIC_SENTRY_DSN,
  NEXT_PUBLIC_GOOGLE_CLIENT_ID: process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID,
  NEXT_PUBLIC_APP_VERSION: process.env.NEXT_PUBLIC_APP_VERSION,
  NEXT_PUBLIC_LOGO: process.env.NEXT_PUBLIC_LOGO || '/logo.png',
}), [])
